import { useContext, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Navigation } from "swiper";

// import { motion } from 'framer-motion'

import "swiper/css";
import "swiper/css/pagination";

import './Promo100.css';
import { ArrowIcon } from '../../../../assets/icons/icons';
import { Link } from 'react-router-dom';
import { CATALOG_MAIN_LINK, IMG_PLACEHOLDER_PROMO } from '../../../../assets/utils/constants';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import { ContactContext } from '../../../../assets/contexts/contactContext';

const getResponsiveImage = (slide, width) => {
    if (width >= 1024 && slide.image?.urls?.orig) {
        return slide.image.urls.orig;
    } else if (width >= 768 && width < 1024 && slide.image_tablet?.urls?.orig) {
        return slide.image_tablet.urls.orig;
    } else if (width < 768 && slide.image_mobile?.urls?.orig) {
        return slide.image_mobile.urls.orig;
    } else {
        // Возвращаем заглушку, если нет изображения для текущего размера экрана
        return slide.image?.urls?.orig || IMG_PLACEHOLDER_PROMO;
    }
};

function parseString(input) {
    if (!input) {
        return {
            title: null,
            subtitle: null,
        }
    }
    const separator = "||";

    // Check if the input contains the separator
    if (input.includes(separator)) {
        const [title, subtitle] = input.split(separator);
        return {
            title: title.trim(),
            subtitle: subtitle.trim(),
        };
    }

    // If no separator, return title and null subtitle
    return {
        title: input.trim(),
        subtitle: null,
    };
}

// isContactPopupOpen,
// setContactPopupOpen,
function SlideContent({ slide }) {
    const { width } = useWindowSize()
    const imageUrl = getResponsiveImage(slide, width);
    const { setContactPopupOpen: setContactOpen } = useContext(ContactContext);

    function openContactPopup() {
        setContactOpen(true);
    }
    const text = parseString(slide.title)
    return (
        <>
            <div className='promo100__slide__box'>
                <div className='promo100__slide-text-box'>
                    {text.title ? <h2 className='promo100__slide-title'>{text.title}</h2> : null}
                    {text.subtitle ? <p className='promo100__slide-subtitle'>{text.subtitle}</p> : null}
                </div>

                {text.title || text.subtitle ?
                    <div className='promo100__slide__box-btn'>
                        <Link className='promo100__slide__catalog-btn' to={CATALOG_MAIN_LINK}>Каталог</Link>
                        <button className='promo100__slide__popup-btn' onClick={openContactPopup}>Связаться</button>
                    </div>
                    :
                    null}
            </div>
            <img
                className="promo100__slide-img"
                // src={`${MAIN_URL}/get-file/${item.file_path.orig}`}
                src={`${imageUrl}`}
                alt=""></img>
        </>

    )
}


function Promo100({
    item,
}) {

    const [my_swiper, set_my_swiper] = useState({});
    console.log({ item })
    return (
        <div className='promo100'>
            {/* {item.data && item.data.length > 1 ?
                <button className="promo100__swiper-arrow-btn promo100__swiper-arrow-btn_left" type="button"
                    onClick={() => {
                        if (my_swiper) {
                            my_swiper.slidePrev();
                        }
                    }}>
                    <ArrowIcon
                        mainClassName={'promo100__swiper-arrow-icon'}
                        fillClassName={'promo100__swiper-arrow-icon-fill'}
                    />
                </button>
                : null
            } */}
            <Swiper
                slidesPerView={1}
                loop={true}
                spaceBetween={16}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                }}
                mousewheel={{
                    forceToAxis: true,
                }}

                modules={[Mousewheel, Pagination]}
                preventInteractionOnTransition={true}
                className="promo100__slides"
                onInit={(ev) => {
                    set_my_swiper(ev)
                }}

            >
                {item.data.map((slide, i2) => (
                    <SwiperSlide className="promo100__slide" key={`promo-100-${item._id}-slide-${i2}`}>
                        {slide.link ?
                            slide.link.startsWith('/') ?
                                <Link to={slide.link} style={{ display: 'flex' }}>
                                    <SlideContent slide={slide} />
                                </Link>
                                :
                                <a href={slide.link} target='_blank' rel='noreferrer' style={{ display: 'flex' }}>
                                    <SlideContent slide={slide} />
                                </a>
                            :
                            <div style={{ display: 'flex' }}>
                                <SlideContent slide={slide} />
                            </div>
                        }

                    </SwiperSlide>
                ))}
            </Swiper>
            {/* {item.data && item.data.length > 1 ?
                <button className="promo100__swiper-arrow-btn promo100__swiper-arrow-btn_right" type="button"
                    onClick={() => {
                        if (my_swiper) {
                            my_swiper.slideNext();
                        }
                    }}>
                    <ArrowIcon
                        mainClassName={'promo100__swiper-arrow-icon'}
                        fillClassName={'promo100__swiper-arrow-icon-fill'}
                    />
                </button>
                : null} */}
        </div>
    );
}

export default Promo100