import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import mainApi from "../../../assets/api/MainApi";
import { ArrowIcon, CatalogIcon, CloseIcon } from "../../../assets/icons/icons";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";

import "./MenuPopup.css";
import { CATALOG_MAIN_LINK, IMG_PLACEHOLDER_PRODUCT } from "../../../assets/utils/constants";
import { ConfigContext } from "../../../assets/contexts/configContext";
import menuFlower from '../../../assets/images/menu-flower.webp'
import { DEFAULT_SOCIAL_VALUES_MAP } from "../../../assets/utils/socials/socials_constants";

function MenuPopup({ isOpened, setOpened, categories }) {
  const [selectedCategory, setSelectCategory] = useState(undefined);
  const [isPreloaderVisible, setPreloaderVisible] = useState(false);
  const [subcategories, setSubcategories] = useState(undefined);

  const config = useContext(ConfigContext)
  const { initialConstants } = config
  const { CONSTANTS } = initialConstants
  const { SHOW_SOCIALS_ICON_IN_FOOTER = true, SHOW_SOCIALS_NAME_IN_FOOTER = false } = CONSTANTS
  const { SHOP_ID_FOR_API, SOCIAL_NETWORKS } = initialConstants

  function close() {
    console.log("sas");
    setOpened(false);
    setTimeout(() => {
      setOpened(false);
    }, 1);
  }

  const navigate = useNavigate()
  function handleSelectCategory(item) {
    if (selectedCategory && selectedCategory._id === item._id) {
      navigate(`/${CATALOG_MAIN_LINK}/${item.translit_name}`);
      close()
      return
    };
    if (item.is_final) {
      navigate(`/${CATALOG_MAIN_LINK}/${item.translit_name}`);
      setSelectCategory(item);
      setSubcategories(undefined)
      close()
      return
    }

    console.log(item);
    setSelectCategory(item);
    setPreloaderVisible(true);
    mainApi
      .getCategories({
        limit: 25,
        shop_id: SHOP_ID_FOR_API,
        _id: item._id,
      })
      .then((res) => {
        console.log(res.data);
        setSubcategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreloaderVisible(false);
      });
  }


  const [selectedType, setSelectedType] = useState("catalog");

  return (
    <>
      {categories && categories.length > 0 && Array.isArray(categories) ? (
        <div
          className={`menu-popup__container ${isOpened ? "menu-popup__container_active" : ""
            }`}
        >
          <div
            className={`menu-popup ${isOpened ? "menu-popup_active" : "menu-popup_inactive"
              }`}
          >
            <div>
              <div className="menu-popup__header">
                <button className="menu-popup__close" type="button" onClick={close}>
                  <CloseIcon
                    mainClassName={"menu-popup__close-icon"}
                    fillClassName={"menu-popup__close-icon-fill"}
                  />
                </button>
              </div>

              <Link className="menu-popup__logo" to={"/"}>
                <img
                  className="menu-popup__logo-img"
                  src={config.platfromTokens.logo.url}
                  alt=""
                />
              </Link>
              <div className="menu-popup__line"></div>
              {selectedType === "catalog" ? (
                <div className="menu-popup__cards">
                  {categories.map((item, i) => (
                    <div className="menu-popup__card-box">
                      <div
                        className={`menu-popup__card ${selectedCategory && selectedCategory._id === item._id
                          ? "menu-popup__card_selected"
                          : ""
                          }`}
                        key={`menu-popup__card${selectedType}${i}${item._id}`}
                        onClick={() => {
                          handleSelectCategory(item);
                        }}
                      >
                        <div className="menu-popup__card-info">
                          <img
                            className='category-block__card__icon'
                            src={item.file ? item.file.urls.orig : IMG_PLACEHOLDER_PRODUCT}
                            alt={item.name}
                          ></img>
                          <p className="menu-popup__card-name">{item.name}</p>
                        </div>
                        <ArrowIcon
                          mainClassName={"menu-popup__card-arrow"}
                          fillClassName={"menu-popup__card-arrow-fill"}
                        />
                      </div>
                      {selectedCategory && selectedCategory._id === item._id ? (
                        isPreloaderVisible ? (
                          <div className="menu-popup__card-preloader">
                            <MiniPreloader />
                          </div>
                        ) : subcategories && subcategories.length > 0 ? (
                          <div className="menu-popup__card-subcategories">
                            {subcategories.map((sub, i) => (
                              <Link
                                className="menu-popup__card-subcategory"
                                to={`/${CATALOG_MAIN_LINK}/${selectedCategory.translit_name}/${sub.translit_name}`}
                                onClick={close}
                              >
                                {sub.name}
                                {/* <ArrowIcon mainClassName={'menu-popup__card-subcategory-arrow'} fillClassName={'menu-popup__card-arrow-fill'} /> */}
                              </Link>
                            ))}
                          </div>
                        ) : null
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div className="menu-popup__socials-box">
              {SOCIAL_NETWORKS && Array.isArray(SOCIAL_NETWORKS) && SOCIAL_NETWORKS.length > 0 ?
                <div className='menu-popup__socials'>
                  <div className="menu-popup__socials__separator"></div>
                  {SOCIAL_NETWORKS.map((social, i) => {
                    const SOCIAL = DEFAULT_SOCIAL_VALUES_MAP[social.type]
                    return (
                      <a className='footer__social' href={social.url} target='_blank' rel='noreferrer' key={`footer__social${social._id}${i}`}>
                        {SHOW_SOCIALS_ICON_IN_FOOTER ?
                          SOCIAL.icon({ mainClassName: 'footer__social-icon', fillClassName: 'footer__social-icon-fill', bgFillClassName: 'footer__social-icon-bg-fill' }) : null}
                        {SHOW_SOCIALS_NAME_IN_FOOTER ? <p className='footer__social-title'>{SOCIAL.title}</p> : null}
                      </a>
                    )
                  })}
                </div>
                :
                null
              }
              <p className='footer__right-reserved menu-popup__right-reserved'>© Все права защищены – 2024</p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default MenuPopup;
