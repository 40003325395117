import { useContext } from 'react';
import './ShowcaseLine.css';
import { ConfigContext } from '../../../../assets/contexts/configContext';
import ProductCard from '../../../Catalog/ProductCard/ProductCard';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import { getRandomId } from '../../../../assets/utils/utils';
import { Link } from 'react-router-dom';
import { CATALOG_MAIN_LINK, IMG_PLACEHOLDER_PRODUCT } from '../../../../assets/utils/constants';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Grid } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

export function ShowcaseLine1({
    item
}) {
    return (
        <ShowcaseLine
            item={item}
            lines_count={1}
        />
    );
}


export function ShowcaseLine2({
    item
}) {
    return (
        <ShowcaseLine
            item={item}
            lines_count={2}
        />
    );
}


export function ShowcaseLine3({
    item
}) {
    return (
        <ShowcaseLine
            item={item}
            lines_count={3}
        />
    );
}




function ShowcaseLine({
    item,
    lines_count,
}) {
    const { width } = useWindowSize()
    const config = useContext(ConfigContext)
    const { cardTokens, initialConstants } = config
    const {
        cards_in_line,
    } = cardTokens
    const { FIRST_LEVEL_CATEGORIES } = initialConstants

    const { title, products } = item.data[0]
    const _id = getRandomId()

    const CATALOG_CATEGORY = [
        {
            title: 'Входные',
            logo: <svg className='showcase-line__card__icon' xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                <rect width="80" height="80" rx="40" fill="#F3F4F7" />
                <path d="M20 30L40 20L60 30V60H20V30Z" stroke="black" stroke-width="3" />
                <rect x="34" y="38" width="12" height="22" stroke="black" stroke-width="3" stroke-linejoin="round" />
                <rect x="42" y="47" width="3" height="3" fill="black" />
            </svg>,
            link: '/catalog/vhodnye-fljn'
        },
        {
            title: 'Межкомнатные',
            logo: <svg className='showcase-line__card__icon' xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                <rect width="80" height="80" rx="40" fill="#F3F4F7" />
                <rect x="20" y="20" width="40" height="40" stroke="black" stroke-width="3" />
                <rect x="30" y="32" width="20" height="28" stroke="black" stroke-width="3" stroke-linejoin="round" />
                <path d="M38 38L50 32V60L38 54V38Z" stroke="black" stroke-width="3" stroke-linejoin="round" />
            </svg>,
            link: '/catalog/mezhkomnatnye-p7fw'
        },
        {
            title: 'Капители',
            logo: <svg className='showcase-line__card__icon' xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                <rect width="80" height="80" rx="40" fill="#F3F4F7" />
                <rect x="25" y="31" width="30" height="10" stroke="black" stroke-width="3" />
                <rect x="25" y="31" width="30" height="5" stroke="black" stroke-width="3" />
                <rect x="25" y="41" width="30" height="19" stroke="black" stroke-width="3" />
                <path d="M20 20H60L55 31H25L20 20Z" stroke="black" stroke-width="3" />
            </svg>,
            link: '/catalog/kapiteli-1yg1'
        },
        {
            title: 'Фурнитура',
            logo: <svg className='showcase-line__card__icon' xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                <rect width="80" height="80" rx="40" fill="#F3F4F7" />
                <rect x="32" y="20" width="16" height="40" stroke="black" stroke-width="3" />
                <path d="M26 26C26 24.8954 26.8954 24 28 24H32V56H28C26.8954 56 26 55.1046 26 54V26Z" stroke="black" stroke-width="3" />
                <path d="M48 24H52C53.1046 24 54 24.8954 54 26V54C54 55.1046 53.1046 56 52 56H48V24Z" stroke="black" stroke-width="3" />
                <rect x="39" y="37" width="21" height="6" fill="#F3F4F7" stroke="black" stroke-width="3" />
                <rect x="38.5" y="24" width="3" height="3" fill="black" />
                <rect x="38.5" y="53" width="3" height="3" fill="black" />
            </svg>,
            link: '/catalog/furnitura-izzm'
        },
        {
            title: 'Акции',
            logo: <svg className='showcase-line__card__icon' xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                <rect width="80" height="80" rx="40" fill="#F3F4F7" />
                <path d="M40 21L46.254 31.3921L58.0701 34.1287L50.1192 43.2879L51.1679 55.3713L40 50.64L28.8321 55.3713L29.8808 43.2879L21.9299 34.1287L33.746 31.3921L40 21Z" stroke="black" stroke-width="3" />
                <rect x="35" y="43" width="10" height="17" stroke="black" stroke-width="3" />
            </svg>,
            link: '/catalog/aktsii-sy68'
        },
    ]

    return (
        <div className='showcase-line'>
            <div className='showcase-line__title-and-btn'>
                {title ? <h2 className='showcase-line__title'>{title}</h2> : null}
                <Link to={CATALOG_MAIN_LINK} className='showcase-line__btn showcase-line__btn_pc'>Все товары <span>→</span></Link>
            </div>
       
            <div className={`showcase-line__items showcase-line__items_cards-in-line-${cards_in_line}`}>
                {products.map((item, i) => (
                    <ProductCard
                        item={item}
                        key={`showcase-line_${_id}products_item_${i}`}
                    />
                ))}
            </div>

            <Swiper
                slidesPerView={1.25}
                // loop={true}
                spaceBetween={16}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                }}
                mousewheel={{
                    forceToAxis: true,
                }}
                modules={[Mousewheel, Pagination, Grid]}
                preventInteractionOnTransition={true}
                className="swiper"
                grid={{
                    rows: 2,
                    fill: "row"
                }}
            >
                {products.map((item, i) => (
                    <SwiperSlide className="swiper-slide" key={`promo-100-${item._id}-slide-${i}`}>
                        <ProductCard
                            item={item}
                            key={`showcase-line_${_id}products_item_${i}`}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            <Link to={`/${CATALOG_MAIN_LINK}`} className='showcase-line__btn showcase-line__btn_mobile'>Все товары <span>→</span></Link>
        </div >
    );
}

