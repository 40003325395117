import "./Advantages.css";

function Advantages({ }) {
    const ADVANTAGES = [
        {
            name: `3D-модель интерьера
           </br> в подарок`,
            logo: <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                <path d="M38.75 36.585L31.25 40.915C30.87 41.1344 30.4388 41.2499 30 41.2499C29.5612 41.2499 29.13 41.1344 28.75 40.915L21.25 36.585C20.87 36.3656 20.5544 36.05 20.335 35.67C20.1155 35.2899 20 34.8588 20 34.42V25.58C20 25.1412 20.1155 24.7101 20.335 24.33C20.5544 23.95 20.87 23.6344 21.25 23.415L28.75 19.085C29.13 18.8656 29.5612 18.7501 30 18.7501C30.4388 18.7501 30.87 18.8656 31.25 19.085L38.75 23.415C39.13 23.6344 39.4456 23.95 39.665 24.33C39.8845 24.7101 40 25.1412 40 25.58V34.42C40 34.8588 39.8845 35.2899 39.665 35.67C39.4456 36.05 39.13 36.3656 38.75 36.585Z" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M30 7.5V18.75" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M39.665 24.33L31.25 29.1886C30.8699 29.4079 30.4388 29.5234 30 29.5234C29.5611 29.5234 29.13 29.4079 28.75 29.1886L20.335 24.33" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M30 41.25V29.5212" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M30 52.5C42.4264 52.5 52.5 42.4264 52.5 30C52.5 17.5736 42.4264 7.5 30 7.5C17.5736 7.5 7.5 17.5736 7.5 30C7.5 42.4264 17.5736 52.5 30 52.5Z" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.5225 41.23L20.3021 35.5914" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M49.4774 41.23L39.6978 35.5914" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        },
        {
            name: `Доставка
           </br> в удобное время`,
            logo: <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                <path d="M55 34.9897H46.25C45.9185 34.9897 45.6005 34.8581 45.3661 34.6236C45.1317 34.3892 45 34.0713 45 33.7397V24.9897H53" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M46.3917 46.0982C46.9162 46.6226 47.2734 47.2908 47.4181 48.0182C47.5629 48.7456 47.4887 49.4997 47.2049 50.1849C46.9211 50.8701 46.4404 51.4558 45.8238 51.8679C45.2071 52.28 44.4821 52.5 43.7404 52.5C42.9987 52.5 42.2737 52.2802 41.657 51.8681C41.0403 51.4561 40.5596 50.8705 40.2758 50.1853C39.9919 49.5 39.9176 48.746 40.0623 48.0186C40.2069 47.2912 40.564 46.623 41.0884 46.0985C41.7917 45.3953 42.7457 44.9999 43.7402 44.9999C44.7347 44.9999 45.6885 45.395 46.3917 46.0982Z" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18.8917 46.0982C19.4162 46.6226 19.7734 47.2908 19.9181 48.0182C20.0629 48.7456 19.9887 49.4997 19.7049 50.1849C19.4211 50.8701 18.9404 51.4558 18.3238 51.8679C17.7071 52.28 16.9821 52.5 16.2404 52.5C15.4988 52.5 14.7737 52.2802 14.157 51.8681C13.5403 51.4561 13.0596 50.8705 12.7758 50.1853C12.4919 49.5 12.4176 48.746 12.5623 48.0186C12.7069 47.2912 13.064 46.623 13.5884 46.0985C14.2915 45.3954 15.2459 44.9996 16.2402 44.9996C17.2346 44.9996 18.1886 45.3951 18.8917 46.0982Z" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M40.2039 47.5H19.7764" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M47.2761 47.5H52.5C53.163 47.5 53.7989 47.2366 54.2678 46.7678C54.7366 46.2989 55 45.6631 55 45V30.9532C55 30.3174 54.8787 29.6875 54.6425 29.0972L51.2557 20.6325C50.8846 19.7048 50.2439 18.9095 49.4165 18.3493C48.589 17.7892 47.6127 17.4898 46.6135 17.4897H37.5" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.704 47.5H7.5C6.83696 47.5 6.20107 47.2366 5.73223 46.7678C5.26339 46.2989 5 45.663 5 45V30" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5 37.4897H35C35.663 37.4897 36.2989 37.2264 36.7678 36.7575C37.2366 36.2887 37.5 35.6528 37.5 34.9897V14.9897C37.5 14.3267 37.2366 13.6908 36.7678 13.222C36.2989 12.7531 35.663 12.4897 35 12.4897H32.5" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15 25C20.5228 25 25 20.5228 25 15C25 9.47715 20.5228 5 15 5C9.47715 5 5 9.47715 5 15C5 20.5228 9.47715 25 15 25Z" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.375 11.625V15.625" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.375 15.625L17.5 17.5" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        },
        {
            name: `Готовые интерьерные
            </br> решения`,
            logo: <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                <path d="M7.5 49.3264V52.5" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M32.5 33.75V30C32.5 28.0109 31.7098 26.1032 30.3033 24.6967C28.8968 23.2902 26.9891 22.5 25 22.5H17.5C15.5109 22.5 13.6032 23.2902 12.1967 24.6967C10.7902 26.1032 10 28.0109 10 30V33.75" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M35 49.3218V52.5" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.25 50H32.5C33.8261 50 35.0979 49.4732 36.0355 48.5355C36.9732 47.5979 37.5 46.3261 37.5 45V37.5C37.5 36.5054 37.1049 35.5516 36.4017 34.8483C35.6984 34.1451 34.7446 33.75 33.75 33.75H32.5C31.5054 33.75 30.5516 34.1451 29.8483 34.8483C29.1451 35.5516 28.75 36.5054 28.75 37.5V42.5H13.75V37.5C13.75 36.5054 13.3549 35.5516 12.6517 34.8483C11.9484 34.1451 10.9946 33.75 10 33.75H8.75C7.75544 33.75 6.80161 34.1451 6.09835 34.8483C5.39509 35.5516 5 36.5054 5 37.5V45C5 46.3261 5.52678 47.5979 6.46447 48.5355C7.40215 49.4732 8.67392 50 10 50H11.25Z" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M47.5 50V22.5" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M51.427 5H43.5729C43.0296 5 42.5011 5.17697 42.0674 5.50413C41.6337 5.83129 41.3184 6.29083 41.1691 6.8132L37.5977 19.3132C37.4914 19.6853 37.4729 20.0769 37.5437 20.4574C37.6144 20.8378 37.7726 21.1966 38.0056 21.5055C38.2386 21.8144 38.5402 22.065 38.8865 22.2376C39.2329 22.4102 39.6145 22.5 40.0015 22.5H54.9984C55.3854 22.5 55.7671 22.4102 56.1134 22.2376C56.4597 22.065 56.7613 21.8144 56.9943 21.5055C57.2273 21.1966 57.3855 20.8378 57.4563 20.4574C57.527 20.0769 57.5086 19.6853 57.4023 19.3132L53.8308 6.8132C53.6816 6.29083 53.3662 5.83129 52.9325 5.50413C52.4988 5.17697 51.9703 5 51.427 5Z" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        },
        {
            name: `Доступные
            </br> цены`,
            logo: <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                <path d="M33.8209 10.5556C33.8238 7.48615 27.3673 5 19.4119 5C11.4565 5 5.00576 7.48892 5 10.5556C5 13.6251 11.4508 16.1113 19.4119 16.1113C27.373 16.1113 33.8238 13.6251 33.8238 10.5556" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M33.824 10.5557V32.1115" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.00293 21.6669C5.00293 24.7364 11.4537 27.2225 19.4148 27.2225C27.376 27.2225 33.8267 24.7364 33.8267 21.6669" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M50.202 33.6616C55.2664 38.5422 55.2664 46.459 50.202 51.3396C45.1377 56.2202 36.9229 56.2202 31.8586 51.3396C26.7942 46.459 26.7942 38.5422 31.8586 33.6616C36.9229 28.7809 45.1377 28.7809 50.202 33.6616Z" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M29.5147 36.7364C26.9148 37.7226 23.3493 38.3337 19.4119 38.3337C11.4537 38.3337 5.00293 35.8475 5.00293 32.778" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M29.3417 47.9006C26.7562 48.8534 23.2714 49.4451 19.4119 49.4451C11.4536 49.4451 5.00288 46.9589 5 43.8894V10.5556" stroke="#101828" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        },
    ]

    return (
        <div className="advantages">
                {ADVANTAGES.map((item, i) => {
                    return (
                        <div className="advantages__card" key={i}>
                            {item.logo}
                            <p className="advantages__card__text" dangerouslySetInnerHTML={{
                                __html: item.name,
                            }}></p>
                        </div>
                    )
                })}
        </div>
    );
}

export default Advantages;