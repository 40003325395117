import "./MapBlock.css";

function MapBlock({ }) {

    return (
        <div className="map-block">
            {/* <iframe className="map-block" src="https://yandex.ru/map-widget/v1/?um=constructor%3Aa4508eab863772b4dad0ba6ee03daee3909660125119166f8cfcedaa4ed9c99d&amp;source=constructor" width="1280" height="460" frameborder="0"></iframe> */}
            <iframe className="map-block" src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae85fd8078f08ad23f8cc0349c71d9a11aeb8784e643e584d32c819058d602e04&amp;source=constructor" width="1280" height="460" frameborder="0"></iframe>
            <div className="map-block__text-box">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <path d="M10.9998 12.0476C9.26389 12.0476 7.85693 10.6406 7.85693 8.9047C7.85693 7.16879 9.26389 5.76184 10.9998 5.76184C12.7357 5.76184 14.1426 7.16879 14.1426 8.9047C14.1426 10.6406 12.7357 12.0476 10.9998 12.0476Z" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10.9998 20.4286C10.9998 20.4286 3.6665 14.4047 3.6665 8.90474C3.6665 4.85465 6.94974 1.57141 10.9998 1.57141C15.0499 1.57141 18.3332 4.85465 18.3332 8.90474C18.3332 14.4047 10.9998 20.4286 10.9998 20.4286Z" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p className="map-block__text">Владивосток, Калинина, 275А, ТЦ Зеленый Остров</p>
            </div>
        </div>
    );
}

export default MapBlock;