import "./AboutBlock.css";
import about from '../../../../assets/images/about.png'
import circle from '../../../../assets/images/about-circle.png'

function AboutBlock({ }) {
    const text = `
    Наш мебельный салон является официальным представителем Фабрики Мирлачева во Владивостоке.
    <br></br>
Уникальные модели, часть которых является авторской разработкой фабрики. Готовые интерьеры, в которых собраны мягкая, корпусная мебель,а также картины, текстиль и другие аксессуары. Мы всегда рады помочьв подборе тканей. А также можем сделать для вас 3D модель интерьера.`

    return (
        <div className="about-block">
            <div className="about-block__line"></div>
            <div className="about-block__box">
                <img src={circle} alt="" className="about-block__circle"></img>
                <img src={about} alt="" className="about-block__img"></img>
                <p className="about-block__text" dangerouslySetInnerHTML={{
                    __html: text,
                }}></p>
            </div>
        </div>
    );
}

export default AboutBlock;