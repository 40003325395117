import "./CategoryBlock.css";
import { useContext } from "react";
import { ConfigContext } from "../../../../assets/contexts/configContext";
import { CATALOG_MAIN_LINK, IMG_PLACEHOLDER_PRODUCT } from "../../../../assets/utils/constants";
import { Link } from "react-router-dom";
import arrow from '../../../../assets/images/sale-arrow.png'
import arrowMobile from '../../../../assets/images/sale-arrow-mobile.png'

function CategoryBlock({ }) {
    const config = useContext(ConfigContext)
    const { initialConstants } = config
    const { FIRST_LEVEL_CATEGORIES } = initialConstants

    return (
        <div className='category-block'>
            <div className='category-block__category'>
                {FIRST_LEVEL_CATEGORIES.map((item, i) => {
                    console.log(item)
                    return (
                        <Link to={`/${CATALOG_MAIN_LINK}/${item.translit_name}`} className='category-block__category-card'>
                            <p className="category-block__number">{("00" + item.count).slice(-2)}</p>
                            <img
                                className='category-block__card__icon'
                                src={item.file ? item.file.urls.orig : IMG_PLACEHOLDER_PRODUCT}
                                alt={item.name}
                            ></img>
                            <p className='category-block__category-card__text'>{item.name}</p>
                        </Link>
                    )
                })}
            </div>
            <Link to={'docs/special-offer'} className="category-block__sale">
                <img src={arrow} alt="" className="category-block__sale__arrow"></img>
                <img src={arrowMobile} alt="" className="category-block__sale__arrow_mobile"></img>
            </Link>
        </div>
    );
}

export default CategoryBlock;